const searchBtn = document.querySelector('.item-search');
const searchForm = document.querySelector('.header__search');
const burger = document.querySelector('.header__burger');
const mobileMenu = document.querySelector('.burger__toggle');
const btnPop = document.querySelectorAll('.btnPop');
const popUp = document.querySelector('.overflow');
const lang = document.querySelector('.header__lang');
const header = document.querySelector('.header');
const faqItems = document.querySelectorAll('.faq__item');

/*search*/
document.addEventListener('click', (e)=> {
        const withinBoundaries = e.composedPath().includes(searchBtn);
        const withinBoundaries1 = e.composedPath().includes(searchForm);
        if ( ! withinBoundaries && !withinBoundaries1 ) {
            searchForm.classList.remove('active')
            searchBtn.classList.remove('active')
        }

        header.addEventListener("mouseleave", () => {
            setTimeout(()=> {
                searchForm.classList.remove('active');
                searchBtn.classList.remove('active')
            },1000)

        });

})
searchBtn.addEventListener('click', (e)=> {
    searchForm.classList.add('active');
    searchBtn.classList.add('active');
})

/*mobile menu*/
burger.addEventListener('click', (e)=> {
    mobileMenu.classList.toggle('active')
})

/*language*/
lang.addEventListener('click', (e)=> {
    document.querySelector('.lang__ru').classList.toggle('active')
    document.querySelector('.lang__en').classList.toggle('active')
})

/*pop up all btn*/
btnPop.forEach(item => {
    item.addEventListener('click', event => {
        popUp.classList.add('active')
    })
})

document.querySelector('.popUp__close').addEventListener('click', (e)=> {
    popUp.classList.remove('active')
})

document.querySelector('.up').addEventListener('click', (e)=> {
    popUp.classList.remove('active')
})

/*up button*/
function scrollTo(to, duration = 700) {
    const
        element = document.scrollingElement || document.documentElement,
        start = element.scrollTop,
        change = to - start,
        startDate = +new Date(),
        // t = current time
        // b = start value
        // c = change in value
        // d = duration
        easeInOutQuad = function (t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        },
        animateScroll = function () {
            const currentDate = +new Date();
            const currentTime = currentDate - startDate;
            element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
            if (currentTime < duration) {
                requestAnimationFrame(animateScroll);
            }
            else {
                element.scrollTop = to;
            }
        };
    animateScroll();
}
const btn = document.querySelector('.up');
document.addEventListener('DOMContentLoaded', function () {

    if(window.pageYOffset > 100) {
        header.classList.add('active');
    }
    window.addEventListener('scroll', function () {
        if (window.pageYOffset > 100) {
            btn.classList.add('show');
            header.classList.add('active');
        } else {
            btn.classList.remove('show');
            header.classList.remove('active')
        }
    });

    // При клике прокручиываем на самый верх
    btn.onclick = function (click) {
        click.preventDefault();
        scrollTo(0, 400);
    }
});

// services slider mobile
const swiper = new Swiper('.swiper', {
    // Default parameters
    slidesPerView: 1.5,
    spaceBetween: 0,
    centeredSlides: true,
    loop: true,
    autoplay: true,
    slidesPerGroup: 1,
    speed: 5000,
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },

    renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
    // Responsive breakpoints
    breakpoints: {
        767: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            speed: 10000,
        }
    }
})


/*img about us*/
const img = document.querySelector('.tasks__img');
document.addEventListener('DOMContentLoaded', function () {

    window.addEventListener('scroll', function () {
        if (window.pageYOffset > 700) {
            img.classList.add('active');
        } else {
            img.classList.remove('active');
        }
    });

    // При клике прокручиываем на самый верх
    btn.onclick = function (click) {
        click.preventDefault();
        scrollTo(0, 400);
    }
});
const img2 = document.querySelector('.about__img');
document.addEventListener('DOMContentLoaded', function () {

    window.addEventListener('scroll', function () {
        if (window.pageYOffset > 900) {
            img.classList.add('active');
        } else {
            img.classList.remove('active');
        }
    });

    // При клике прокручиываем на самый верх
    btn.onclick = function (click) {
        click.preventDefault();
        scrollTo(0, 400);
    }
});



/*services*/
// document.getElementById('block1').addEventListener("mouseenter", () => {
//     document.querySelector('.services__info').classList.add('active');
//     document.querySelector('.obor').classList.add('active');
//     document.querySelector('.circle__border').classList.add('active');
//
//     document.getElementById('block1').addEventListener("mouseleave", () => {
//         document.querySelector('.services__info').classList.remove('active');
//         document.querySelector('.obor').classList.remove('active');
//         document.querySelector('.circle__border').classList.remove('active');
//     });
// });
// document.getElementById('block1').addEventListener("mouseenter", () => {
//     document.querySelector('.services__info').classList.add('active');
//     document.querySelector('.obor').classList.add('active');
//     document.querySelector('.circle__border').classList.add('active');
//
//     document.getElementById('block1').addEventListener("mouseleave", () => {
//         document.querySelector('.services__info').classList.remove('active');
//         document.querySelector('.obor').classList.remove('active');
//         document.querySelector('.circle__border').classList.remove('active');
//     });
// });

const serviceNames = document.querySelectorAll('.name__value');
const serviceInfos = document.querySelectorAll('.info__text');
const serviceImg = document.querySelectorAll('.circle__img');
const wrapInfo = document.querySelector('.services__info');

serviceNames.forEach(item => {
    item.addEventListener('mouseenter', event => {
        item.classList.add('active');
        wrapInfo.classList.add('active');
        Object.values(serviceInfos).filter(info => info.dataset.name === item.dataset.name)[0].classList.add('active')
        Object.values(serviceImg).filter(info => info.dataset.name === item.dataset.name)[0].classList.add('active')
        document.querySelector('.circle__border').classList.add(item.dataset.name);
        document.querySelector('.circle__border').classList.add('active');

        serviceNames.forEach(item => {
            item.addEventListener('mouseleave', event => {
                item.classList.remove('active');
                wrapInfo.classList.remove('active');
                Object.values(serviceInfos).filter(info => info.dataset.name === item.dataset.name)[0].classList.remove('active')
                Object.values(serviceImg).filter(info => info.dataset.name === item.dataset.name)[0].classList.remove('active')
                wrapInfo.classList.remove('active');
                document.querySelector('.circle__border').classList.remove('active');
                document.querySelector('.circle__border').classList.remove(item.dataset.name);
            })
        })
    })
})

serviceImg.forEach(item => {
    item.addEventListener('mouseenter', event => {
        item.classList.add('active');
        wrapInfo.classList.add('active');
        Object.values(serviceInfos).filter(info => info.dataset.name === item.dataset.name)[0].classList.add('active')
        Object.values(serviceNames).filter(info => info.dataset.name === item.dataset.name)[0].classList.add('active')
        document.querySelector('.circle__border').classList.add(item.dataset.name);
        document.querySelector('.circle__border').classList.add('active');
        serviceImg.forEach(item => {
            item.addEventListener('mouseleave', event => {
                item.classList.remove('active');
                wrapInfo.classList.remove('active');
                Object.values(serviceInfos).filter(info => info.dataset.name === item.dataset.name)[0].classList.remove('active')
                Object.values(serviceNames).filter(info => info.dataset.name === item.dataset.name)[0].classList.remove('active')
                wrapInfo.classList.remove('active');
                document.querySelector('.circle__border').classList.remove('active');
                document.querySelector('.circle__border').classList.remove(item.dataset.name);
            })
        })
    })
})





// companies slider
const swiperCompanies = new Swiper('.companies__slider', {
    // Default parameters
    slidesPerView: 2.3,
    spaceBetween: 0,
    centeredSlides: true,
    autoplay: true,
    loop: true,
    slidesPerGroup: 1,
    speed: 5000,
    // Responsive breakpoints
    breakpoints: {
        768: {
            slidesPerView: 4.3,
            speed: 10000,
            centeredSlides: true,
        },
        1024: {
            slidesPerView: 6.3,
            speed: 10000,
            centeredSlides: true,
        },
        1280: {
            slidesPerView: 8.3,
            speed: 10000,
            centeredSlides: true,
        },
        1600: {
            slidesPerView: 10.3,
            speed: 10000,
            centeredSlides: true,
        },
    }
})

const swiperArticles = new Swiper('.articles__wrapper', {
    // Default parameters
    slidesPerView: 1.3,
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    slidesPerGroup: 1,
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },

    renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
    breakpoints: {
        767: {
            slidesPerView: 2.3,
        },
        1023: {
            slidesPerView: 3.2,
        },
        1024: {
            slidesPerView: 3,
            loop: false,
            centeredSlides: false,
        },
    }
})

faqItems.forEach(item => {
    item.addEventListener('click', event => {
        faqItems.forEach(item => item.classList.remove('active') )
        item.classList.add('active');
    })
})
var swiper3 = new Swiper('.clients__slider', {
    slidesPerView: 6,
    slidesPerColumn: 5,
    slidesPerColumnFill: 'column',
    autoplay: true,
    speed: 5000,
    breakpoints: {
        767: {
            slidesPerView: 2.1,
        },
        1023: {
            slidesPerView: 4,
            slidesPerColumn: 4,
        },
    }
});
